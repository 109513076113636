<template>
  <div>
    <div>
      <el-select style="margin-left: 5px;margin-bottom: 20px;margin-right: 20px" v-model="oper" placeholder="请选择标段">
        <el-option
            v-for="item in bdsts"
            :key="item.bdstname"
            :label="item.bdstname"
            :value="item.bdstname"
        >
        </el-option>
      </el-select>
      <el-input style="width: 250px;margin-right: 20px" v-model="username" placeholder="请输入姓名或拼音首字母" clearable />
      <el-button style="width: 80px" type="warning" @click="getSalUser"> 查询 </el-button>
      <el-button style="width: 80px" type="info" @click="addSalaryUser"> 新增 </el-button>
    </div>
<!--    表格数据-->
    <div>
      <vxe-table
          border="inner"
          :align="allAlign"
          show-overflow
          auto-resize
          height="700"
          :data="users"
          :edit-config="{trigger: 'dblclick', mode: 'cell'}">
        <vxe-column type="seq" title="序号" width="40" fixed="left"></vxe-column>
        <vxe-column field="unit" width="80" title="单位" fixed="left"></vxe-column>
        <vxe-column field="name" width="80" title="姓名" fixed="left"></vxe-column>
        <vxe-column field="idCardNumber" width="180" title="身份证号"></vxe-column>
        <vxe-column field="phone" width="120" title="手机号"></vxe-column>
        <vxe-column field="age" width="60" title="年龄"></vxe-column>
        <vxe-column field="nationality" width="60" title="民族"></vxe-column>
        <vxe-column field="education" width="60" title="最高学历"></vxe-column>
        <vxe-column field="employType" width="120" title="聘用形式"></vxe-column>
        <vxe-column width="120" title="员工状态">
          <template #default="{ row }">
            <el-tag v-if="row.employStatus === 1" type="success" size="mini">在职</el-tag>
            <el-tag v-if="row.employStatus === 0" type="danger" size="mini">离职</el-tag>
          </template>
        </vxe-column>
        <vxe-column field="bankinfo" width="120" title="银行开户行"></vxe-column>
        <vxe-column field="bankcardnumber" width="120" title="工资卡卡号"></vxe-column>
        <vxe-column field="email" width="180" title="邮箱" :edit-render="{autofocus: '.vxe-input--inner'}">
          <template #edit="{ row }">
            <vxe-input v-model="row.email" type="text" @blur="nameChangeEmail(row)"></vxe-input>
          </template>
        </vxe-column>
        <vxe-column field="hiredTime" width="120" title="起薪日期" :edit-render="{autofocus: '.vxe-input--inner'}">
          <template #edit="{ row }">
            <vxe-input v-model="row.hiredTime" type="text" @blur="nameChangehiredtime(row)"></vxe-input>
          </template>
        </vxe-column>
        <vxe-column field="postPosition" width="120" title="上报职务" :edit-render="{autofocus: '.vxe-input--inner'}">
          <template #edit="{ row }">
            <vxe-input v-model="row.postPosition" type="text" @blur="nameChangepostposition(row)"></vxe-input>
          </template>
        </vxe-column>
        <vxe-column field="approvePosition" width="120" title="集团公司批复职务" :edit-render="{autofocus: '.vxe-input--inner'}">
          <template #edit="{ row }">
            <vxe-input v-model="row.approvePosition" type="text" @blur="nameChangeapproveposition(row)"></vxe-input>
          </template>
        </vxe-column>
        <vxe-column field="originalUnitLevel" width="120" title="原单位级别" :edit-render="{autofocus: '.vxe-input--inner'}">
          <template #edit="{ row }">
            <vxe-input v-model="row.originalUnitLevel" type="text" @blur="nameChangeoriginalunitlevel(row)"></vxe-input>
          </template>
        </vxe-column>
        <vxe-column field="originalLevel" width="120" title="原级别" :edit-render="{autofocus: '.vxe-input--inner'}">
          <template #edit="{ row }">
            <vxe-input v-model="row.originalLevel" type="text" @blur="nameChangeoriginallevel(row)"></vxe-input>
          </template>
        </vxe-column>
        <vxe-column field="anQuanRole" width="120" title="月度考核定级模板-安全" :edit-render="{autofocus: '.vxe-input--inner'}">
          <template #edit="{ row }">
            <vxe-input v-model="row.anQuanRole" type="text" @blur="nameChangeanquanrole(row)"></vxe-input>
          </template>
        </vxe-column>
        <vxe-column field="zhiLiangRole" width="120" title="月度考核定级模板-质量" :edit-render="{autofocus: '.vxe-input--inner'}">
          <template #edit="{ row }">
            <vxe-input v-model="row.zhiLiangRole" type="text" @blur="nameChangezhiliangrole(row)"></vxe-input>
          </template>
        </vxe-column>
        <vxe-column field="hsbRole" width="120" title="月度考核定级模板-环水保" :edit-render="{autofocus: '.vxe-input--inner'}">
          <template #edit="{ row }">
            <vxe-input v-model="row.hsbRole" type="text" @blur="nameChangehsbrole(row)"></vxe-input>
          </template>
        </vxe-column>
        <vxe-column field="technicalTitle" width="120" title="技术职称" :edit-render="{autofocus: '.vxe-input--inner'}">
          <template #edit="{ row }">
            <vxe-input v-model="row.technicalTitle" type="text" @blur="nameChangetechnicaltitle(row)"></vxe-input>
          </template>
        </vxe-column>
        <vxe-column title="操作" width="200" show-overflow>
          <template #default="{ row }">
            <el-popconfirm
                confirm-button-text="确认"
                cancel-button-text="取消"
                icon="el-icon-info"
                icon-color="red"
                title="确认删除"
                @confirm="delSalUser(row)"
                @cancel="cancelEvent"
            >
              <template #reference>
                <el-button type="danger">删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <!--    分页-->
    <div style="float: right;margin-top: 20px;">
      <el-pagination v-model:currentPage="pagenum"
                     :small="true"
                     background layout="prev, pager, next" :total="totals">
      </el-pagination>
    </div>
<!--    新增用户-->
    <div>
      <el-dialog
          v-model="dialogVisible"
          title="新增财务用户"
          width="40%"
      >
        <el-form :model="newuser" label-width="120px">
          <el-form-item label="姓名">
            <el-input v-model="newuser.name" />
          </el-form-item>
          <el-form-item label="身份证号">
            <el-input v-model="newuser.idcard" @blur="getAge"/>
          </el-form-item>
          <el-form-item label="年龄">
            <el-input disabled v-model="newuser.age" @blur="getAge"/>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input v-model="newuser.phone" />
          </el-form-item>
          <el-form-item label="民族">
            <el-input v-model="newuser.mz" />
          </el-form-item>
          <el-form-item label="最高学历">
            <el-input v-model="newuser.heighxl" />
          </el-form-item>
          <el-form-item label="聘用形式">
            <el-select v-model="newuser.pinyon" class="m-2" placeholder="" size="large">
              <el-option
                  v-for="item in pinyon"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="员工状态">
            <el-select v-model="newuser.yuangongstatus" class="m-2" placeholder="" size="large">
              <el-option
                  v-for="item in yuangongstatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="银行开户行">
            <el-input v-model="newuser.yhkhh" />
          </el-form-item>
          <el-form-item label="工资卡卡号">
            <el-input v-model="newuser.yhnum" />
          </el-form-item>
          <el-form-item label="邮箱">
            <el-input v-model="newuser.email" />
          </el-form-item>
        </el-form>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="qxdio">取消</el-button>
        <el-button type="primary" @click="addSalaryUser" >提交</el-button>
      </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
export default {
  name: "salaryuser",
  data(){
    return{
      oper: '',
      bdsts: [],
      username: '',
      totals: 0,
      users: [],
      pagenum: 1,
      allAlign: 'center',
      dialogVisible: false,
      newuser: {
        name: '',
        idcard: '',
        phone: '',
        mz: '',
        heighxl: '',
        pinyon: '',
        yuangongstatus: '',
        yhkhh: '',
        yhnum: '',
        age: '',
        email: ''
      },
      yuangongstatus:[
        {
          value: '1',
          label: '在职',
        },
        {
          value: '2',
          label: '离职',
        }],
      pinyon: [
        {
          value: '正式工',
          label: '正式工',
        },
        {
          value: '临时工',
          label: '临时工',
        },
        {
          value: '劳务派遣',
          label: '劳务派遣',
        }
      ],
      sex: '',
      birthday: ''
    }
  },
  created() {
    this.axios.get('/other/exzxzz', (response) => {
      this.bdsts = response.obj;
      // console.log(this.bdsts)
    },{
      bdst: 1
    });
  },
  methods:{
    getSalUser(){
      this.users = [];
      this.axios.post('/user/getsaluser', (response) => {
        // console.log(response)
        this.totals = response.obj2 * 10;
        this.users = response.obj;
      },{
        bdst: this.oper,
        name: this.username,
        pageCount: 1
      });
    },
    nameChangeEmail(row){
      this.axios.post('/user/upuser', (response) => {
        if (response.obj){
          ElMessage({
            message: '修改成功！',
            type: 'success',
          })
        }else {
          ElMessage.error('修改失败！')
        }
      },{
        id: row.id,
        email: row.email
      })
    },
    nameChangehiredtime(row){
      this.axios.post('/user/upuser', (response) => {
        if (response.obj){
          ElMessage({
            message: '修改成功！',
            type: 'success',
          })
        }else {
          ElMessage.error('修改失败！')
        }
      },{
        id: row.id,
        hiredtime: row.hiredTime
      })
    },
    nameChangepostposition(row){
      this.axios.post('/user/upuser', (response) => {
        if (response.obj){
          ElMessage({
            message: '修改成功！',
            type: 'success',
          })
        }else {
          ElMessage.error('修改失败！')
        }
      },{
        id: row.id,
        postposition: row.postPosition
      })
    },

    nameChangeapproveposition(row){
      this.axios.post('/user/upuser', (response) => {
        if (response.obj){
          ElMessage({
            message: '修改成功！',
            type: 'success',
          })
        }else {
          ElMessage.error('修改失败！')
        }
      },{
        id: row.id,
        approveposition: row.approvePosition
      })
    },
    nameChangeoriginalunitlevel(row){
      this.axios.post('/user/upuser', (response) => {
        if (response.obj){
          ElMessage({
            message: '修改成功！',
            type: 'success',
          })
        }else {
          ElMessage.error('修改失败！')
        }
      },{
        id: row.id,
        originalunitlevel: row.originalUnitLevel
      })
    },
    nameChangeoriginallevel(row){
      this.axios.post('/user/upuser', (response) => {
        if (response.obj){
          ElMessage({
            message: '修改成功！',
            type: 'success',
          })
        }else {
          ElMessage.error('修改失败！')
        }
      },{
        id: row.id,
        originallevel: row.originalLevel
      })
    },
    nameChangeanquanrole(row){
      this.axios.post('/user/upuser', (response) => {
        if (response.obj){
          ElMessage({
            message: '修改成功！',
            type: 'success',
          })
        }else {
          ElMessage.error('修改失败！')
        }
      },{
        id: row.id,
        anquanrole: row.anQuanRole
      })
    },
    nameChangezhiliangrole(row){
      this.axios.post('/user/upuser', (response) => {
        if (response.obj){
          ElMessage({
            message: '修改成功！',
            type: 'success',
          })
        }else {
          ElMessage.error('修改失败！')
        }
      },{
        id: row.id,
        zhiliangrole: row.zhiLiangRole
      })
    },
    nameChangehsbrole(row){
      this.axios.post('/user/upuser', (response) => {
        if (response.obj){
          ElMessage({
            message: '修改成功！',
            type: 'success',
          })
        }else {
          ElMessage.error('修改失败！')
        }
      },{
        id: row.id,
        hsbrole: row.hsbRole
      })
    },
    nameChangetechnicaltitle(row){
      this.axios.post('/user/upuser', (response) => {
        if (response.obj){
          ElMessage({
            message: '修改成功！',
            type: 'success',
          })
        }else {
          ElMessage.error('修改失败！')
        }
      },{
        id: row.id,
        technicaltitle: row.technicalTitle
      })
    },
    delSalUser(row) {
      this.axios.post('/user/delsaluser', (response) => {
        if (response.obj) {
          this.pagenum = '1';
          this.getSalUser();
        } else {
          ElMessage.error('删除失败！')
        }
      }, {
        id: row.id
      })
    },
    cancelEvent(){

    },
    addSalaryUser(){
      if (this.oper === ''){
        ElMessage.error('请在先在下拉框中选择单位!')
      }else {
        this.dialogVisible = true;
        this.axios.post('/user/adduser', (response) => {
          if (response.obj){
            this.dialogVisible = false;
            this.newuser.name = '';
            this.newuser.idcard = '';
            this.newuser.phone = '';
            this.newuser.age = '';
            this.newuser.mz = '';
            this.newuser.heighxl = '';
            this.newuser.pinyon = '';
            this.newuser.yuangongstatus = '';
            this.newuser.yhnum = '';
            this.newuser.yhkhh = '';
            this.newuser.email = '';
          }else{
            ElMessage.error('添加失败，请联系管理员！')
          }
        },{
          unit: this.oper,
          name: this.newuser.name,
          idCardNumber: this.newuser.idcard,
          phone: this.newuser.phone,
          sex: this.sex,
          birthday: this.birthday,
          age: this.newuser.age,
          nationality: this.newuser.mz,
          education: this.newuser.heighxl,
          employType: this.newuser.pinyon,
          employStatus: this.newuser.yuangongstatus,
          bankInfo: this.newuser.yhkhh,
          bandCardNumber: this.newuser.yhnum,
          email: this.newuser.email
        })
      }
    },
    getAge(){
      if (this.newuser.idcard === ''){
        this.newuser.age = '';
      }else{
        const yearBirth = this.newuser.idcard.substring(6, 10);
        const monthBirth = this.newuser.idcard.substring(10,12);
        const dayBirth = this.newuser.idcard.substring(12,14);
        // 获取当前年月日并计算年龄
        const myDate = new Date();
        const monthNow = myDate.getMonth() + 1;
        const dayNow = myDate.getDate();
        let age = myDate.getFullYear() - yearBirth;
        if(monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)){
          age --;
        }
        // 得到年龄
        this.newuser.age = age;
        this.birthday = this.newuser.idcard.substring(6, 10) + "-" + this.newuser.idcard.substring(10, 12) + "-" + this.newuser.idcard.substring(12, 14);
        if (parseInt(this.newuser.idcard.substr(16, 1)) % 2 === 1) {
          this.sex = '男';
        } else {
          this.sex = '女';
        }
      }
    },
    qxdio(){
      this.dialogVisible = false;
      this.newuser.name = '';
      this.newuser.idcard = '';
      this.newuser.phone = '';
      this.newuser.age = '';
      this.newuser.mz = '';
      this.newuser.heighxl = '';
      this.newuser.pinyon = '';
      this.newuser.yuangongstatus = '';
      this.newuser.yhnum = '';
      this.newuser.yhkhh = '';
      this.newuser.email = '';
    }
  },
  watch: {
    pagenum(){
      this.axios.post('/user/getsaluser', (response) => {
        //this.bdsts = response.obj;
        console.log(response)
        this.totals = response.obj.pages * 10;
        this.users = response.obj.records;
      },{
        bdst: this.oper,
        name: this.username,
        pageCount: this.pagenum
      });
    }
  }
}
</script>

<style scoped>

</style>